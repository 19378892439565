import { render, staticRenderFns } from "./logo-garden.vue?vue&type=template&id=abbf2620&scoped=true&lang=pug"
import script from "./logo-garden.vue?vue&type=script&lang=coffee"
export * from "./logo-garden.vue?vue&type=script&lang=coffee"
import style0 from "./logo-garden.vue?vue&type=style&index=0&id=abbf2620&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abbf2620",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlocksLogoGardenCompany: require('/opt/build/repo/site/components/blocks/logo-garden/company.vue').default,Squircle: require('/opt/build/repo/site/components/globals/squircle/index.vue').default})
