
export default

	props: company: Object

	computed:

		classes: -> [
			'has-hover': @hasHover
			'natural': @company.natural
		]

		# Get the width of the logo
		width: -> @company.logo[0]?.width + 'px'

		hasHover: -> !!@company.logoHover.length

		objectFit: -> if !@company.natural then 'contain'

