import { render, staticRenderFns } from "./company.vue?vue&type=template&id=574c8708&scoped=true&lang=pug"
import script from "./company.vue?vue&type=script&lang=coffee"
export * from "./company.vue?vue&type=script&lang=coffee"
import style0 from "./company.vue?vue&type=style&index=0&id=574c8708&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "574c8708",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CraftVisual: require('/opt/build/repo/site/components/globals/craft-visual.coffee').default})
