
import chunk from 'lodash/chunk'
import inViewportMixin from 'vue-in-viewport-mixin'
settings =
	revealStart: -> "top bottom"
	revealEnd: -> "bottom bottom"

export default

	mixins: [inViewportMixin]

	props: block: Object
	computed:

		style: -> backgroundColor: @block.backgroundColor

		# Make list of layouts, with list of chunked companies
		layouts: ->
			if @layoutType == 'fixed' then [
				{ # Desktop
					class: [ 'hide-mobile',  if parseInt(@block.columns) == 5 then 'five-column' ]
					rows: chunk @block.companies, @block.columns
				}
				{ # Mobile
					class: 'hide-desktop'
					rows: chunk @block.companies, 2
				}
			]
			else [
				{ # Desktop
					class: [ 'hide-mobile' ]
					rows: @variableRows
				}
				{ # Mobile
					class: 'hide-desktop'
					rows: chunk @block.companies, 2
				}
			]

		layoutType: -> if @block.columns not in ['4-4-3', '4-3-4'] then "fixed" else "variable"

		# Applicable to layout 4-4-3 and 4-3-4
		# Make an array of arrays of logos separated by rows
		variableRows: ->
			return unless @layoutType == "variable"
			columnsPerRow = @block.columns.split('-').map (columns) -> Number columns
			counter = 0
			result = []

			for logo in @block.companies
				currentRowLogoCount = result[counter]?.length || 0
				if currentRowLogoCount == columnsPerRow[counter] then counter += 1
				if !result[counter] then result[counter] = []
				result[counter].push logo

			return result

		viewportWidth: -> @$store.state.layout.viewport.width

		maxWidth: -> @$interpolate(180, 130	, 375, 1440, @viewportWidth) if @viewportWidth
		maxHeight: -> @$interpolate(35, 20, 375, 1440, @viewportWidth) if @viewportWidth

	# watch: 'inViewport.now': (inViewport) -> if inViewport then @$nextTick => @revealWhenInside()

	methods:
		revealWhenInside: ->
			@timeline = @$gsap.timeline()
				.pause()
				.set @$refs.rows, { opacity: -> 0}
				.fromTo @$refs.rows, { opacity: -> 0 }, { opacity: -> 1 }

			@trigger1 = @$ScrollTrigger.create {
				trigger: @$refs.rows
				start: settings.revealStart
				end: settings.revealEnd
				animation: @timeline
			}

			@$ScrollTrigger.refresh(true)


		constraintDimensions: (maxWidth, maxHeight, intrinsicWidth, intrinsicHeight) ->
			ar = intrinsicWidth / intrinsicHeight
			if (maxWidth / ar) > maxHeight then return {width: maxHeight * ar, height: maxHeight}
			else return {width: maxWidth, height: maxWidth / ar}

		sizes: (company) -> @getSizes(company, @maxWidth, @maxHeight) if @maxWidth && @maxHeight

		getSizes: (company, maxWidth, maxHeight) ->

			logo = company.logo[0]
			return unless logo

			# Occupy entire container if natural set to true on the CMS
			if company.natural
				ar = logo.width / logo.height
				return {width: maxWidth, height: maxWidth / ar}

			@constraintDimensions(maxWidth, maxHeight, logo.width, logo.height)

		getCompanyStyle: (company) ->
			return unless !company.natural
			sizes = @sizes(company)

			return unless sizes
			{width, height} = sizes

			"--width": "#{width}px"
			"--height": "#{height}px"

